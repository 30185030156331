import React, {createContext, useEffect, useMemo, useState} from "react";
import './App.css';
import {Outlet} from "react-router-dom";
import Sidebar from "./components/sidebar/Navbar";
import LayoutApp from "./components/layout";
import Login from "./pages/auth/login/login";
import {CookiesFunctions} from "./common/functions";
import axios from "axios";
import {BASE_API_URL} from "./const/consts";
import './messaging_init_in_sw';
import {Button, notification} from "antd";
import {RadiusUprightOutlined} from "@ant-design/icons";

export const NodeModelContext = createContext(null);
export const LoginContext = createContext(null);
export const NotificationContext = createContext({
    name: 'Default',
});

export const openNotification = (api,placement) => {
    api.info({
        message: `طلب جديد`,
        description: <NotificationContext.Consumer>{({ name }) => `هناك طلب جديد، اذهب لشاشة ادراة الطلبات`}</NotificationContext.Consumer>,
        placement,
    });
};
function App() {
    const cookiesFun = new CookiesFunctions()
    const[isOpen ,setIsOpen] = useState(true);
    const[login ,setLogin] = useState(false);
    const[loading ,setLoading] = useState(true);
    useEffect(()=> {
        getSession()
    },[])
    const getSession = async () => {
        setLoading(true)
        const body = {sessionId:cookiesFun.getSession()}
        console.log(JSON.stringify(body))
        await axios.post(`${BASE_API_URL}auth/sessions/`,body)
            .then(function (response) {
                setLoading(false)
                console.log(response.data)
                if (response.data.running){
                    setLogin(true)
                }else {
                    setLogin(false)
                }
            });
    }
    const contextValue = useMemo(
        () => ({
            name: 'Ant Design',
        }),
        [],
    );
    const toggle = () => setIsOpen (!isOpen);

    return (
      <LoginContext.Provider value={[login ,setLogin]}>
         <div>
             <NotificationContext.Provider value={contextValue}>
                 {login?
                     <div className="pc-mode">
                         <Sidebar isOpen={isOpen}>
                             <LayoutApp toggle={toggle}>
                                 <Outlet />
                             </LayoutApp>
                         </Sidebar>
                     </div>:
                     <Login loadingPage={loading}/>
                 }
                 {/*<div className="mobile-mode center">*/}
                 {/*   <h5>*/}
                 {/*       النظام يعمل على الكموبيوتر*/}
                 {/*   </h5>*/}
                 {/*   <h4>*/}
                 {/*       وضع الموبايل تحت التطوير ...*/}
                 {/*   </h4>*/}
                 {/*</div>*/}
             </NotificationContext.Provider>
         </div>
      </LoginContext.Provider>
   );
}

export default App;
