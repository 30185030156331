import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import {fcm_server_key} from "./const/consts";

const firebaseConfig = {
    apiKey: "AIzaSyAxNIUgEeL1rnhfVrkhw9HFjlD_LoW1JSU",
    authDomain: "alaman-qa.firebaseapp.com",
    projectId: "alaman-qa",
    storageBucket: "alaman-qa.appspot.com",
    messagingSenderId: "200471529110",
    appId: "1:200471529110:web:0ff5b397363df4e80424b7"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


function requestPermission() {
    // console.log("Requesting permission...");
    Notification.requestPermission().then( async (permission) => {
        if (permission === "granted") {
            // console.log("Notification permission granted.");
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                updateViaCache: 'all'
            });
            // const registration = await navigator.serviceWorker.register('./firebase-messaging-sw.js');
            getToken(messaging, {
                serviceWorkerRegistration: registration,
                vapidKey:"BL5rUcETyFgTGJ9tQKh90BvbSB1NyDY8pBThDFrWFrrBjXDNgyr0OgBUKfXXJox8BRiNPj9_F8qp-wFWuWf7aJM"
            }).then((currentToken) => {
                if (currentToken) {
                    console.log("currentToken: ", currentToken);
                    subscribeTokenToTopic(currentToken, "alaman")
                } else {
                    console.log("Can not get token");
                }
            });
        } else {
            console.log("Do not have permission!");
        }
    });
}

function subscribeTokenToTopic(token, topic) {
    fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key='+fcm_server_key
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error subscribing to topic: '+response.status + ' - ' + response.text();
        }
        console.log('Subscribed to "'+topic+'"');
    }).catch(error => {
        console.error(error);
    })
}


export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("inner Message received")
            resolve(payload);
        });
    });




requestPermission();
