

// export const BASE_API_URL = 'https://alaman-qa.com/back2/app/api/';
export const BASE_API_URL = 'https://alaman-qa.com/backend/app/api/';
// export const BASE_API_URL = 'http://127.0.0.1:8888/alaman/backend/app/api/';

export const firebaseConfig = {
    apiKey: "AIzaSyAxNIUgEeL1rnhfVrkhw9HFjlD_LoW1JSU",
    authDomain: "alaman-qa.firebaseapp.com",
    projectId: "alaman-qa",
    storageBucket: "alaman-qa.appspot.com",
    messagingSenderId: "200471529110",
    appId: "1:200471529110:web:0ff5b397363df4e80424b7"
};

export const firebaseKeyPair = "rrmC5ytyF7_XnFEVAcg6fAea0Q8ORpiKNVHdr31TCoc"
export const fcm_server_key = "AAAALq0IxpY:APA91bFzseQCtMoDBf9x7gLdd6KV1vOJu-h1VI9TCaPiVK-cOVV2g_ClmyJEtp15-nylOsm6VdCHa59a67xzb4FgKFucbVq4KZrIwLIc_pCsdUSgq8jYthX5vwJDJVFFbaQ_VTcFRQrc";
