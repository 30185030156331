import React from 'react';
import {Divider, Row, Tag} from "antd";
import {CookiesFunctions} from "../../../common/functions";

const OrderMore = ({order}) => {
    const functions = new CookiesFunctions()

    return (
        <div>
            <h3>تفاصيل اضافية</h3>
            <Divider/>
            <Row>
                <div>تاريخ الحجز</div>
                <Divider type="vertical"/>
                <div>{order.booked_date?order.booked_date:"لم يحدد"}</div>
            </Row>
            <Divider/>
            <Row>
                <div>المدينة</div>
                <Divider type="vertical"/>
                <div>{order.city?
                    functions.getCity(order.city):"لم يحدد"}</div>
            </Row>
            <Divider/>
            <Row>
                <div>العنوان</div>
                <Divider type="vertical"/>
                <div>{order.address?order.address:"لم يحدد"}</div>
            </Row>
            <Divider/>
            <Row>
                <div>حالة الدفع</div>
                <Divider type="vertical"/>
                <div>
                    <Tag color={
                        +order.payment_type === 1? "orange":
                                "blue"}
                    >{+order.payment_type === 1? "كاش":
                            "اونلاين"}</Tag>
                </div>
            </Row>
            <Divider/>
            <Row>
                <div>تاريخ الطلب</div>
                <Divider type="vertical"/>
                <div>{order.created_at?order.created_at:"لم يحدد"}</div>
            </Row>
        </div>
    );
};

export default OrderMore;
