import {Button, Modal} from 'antd';
import OrderMore from "../../pages/orders/order_more_data/order_more";
import ServiceOrder from "../../pages/orders/order_more_data/order_services";
import React, {useState} from "react";
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
const Model = ({isModalOpen,handleOk,handleCancel,call}) => {
    const [loading, setLoading] = useState(false);
    const onFinish = async () => {
        setLoading(true)
        let status = +5;
        const row = {doc_id:isModalOpen.order.doc_id,order_status:status}
        const body = {table:"orders",data:row}
        console.log(JSON.stringify(body))
        await axios.post(`${BASE_API_URL}update/`,body)
            .then(function (response) {
                call()
                setLoading(false)
                handleCancel()
            });
    };
    return (
        <>
            <Modal open={isModalOpen.open} onOk={handleOk} onCancel={handleCancel}
                   okText="موافق" cancelText="تم"
                   footer={[
                       <Button loading={loading} onClick={onFinish} key="submit" htmlType="submit" color="primary"
                               style={{backgroundColor:"red",float:"right"}} type="primary">
                           الغاء الطلب
                       </Button>,
                       <Button onClick={handleCancel} key="submit" htmlType="submit" type="default">
                           الغاء
                       </Button>
                   ]}
            >
                {!isModalOpen.service?
                    <OrderMore order={isModalOpen.order}/>:
                    <ServiceOrder order={isModalOpen.order}/>
                }
            </Modal>
        </>
    );
};
export default Model;
