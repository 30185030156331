import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import {ConfigProvider} from "antd";
import {createHashRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/home/home";
import Services from "./pages/services/services";
import Orders from "./pages/orders/orders";
import Users from "./pages/users/users";
import Workers from "./pages/workers/workers";
import Invoice from "./components/invoice/invoice";
import './messaging_init_in_sw';

const router = createHashRouter([
    {
        element: <App />,
        children: [
            {
                path: "*",
                element: <Home />,
            },
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "services",
                element: <Services />,
            },
            {
                path: "orders/:newOrders",
                element: <Orders />,
            },
            {
                path: "users",
                element: <Users />,
            },
            {
                path: "workers",
                element: <Workers />,
            },
            {
                path: "invoice/:id",
                element: <Invoice />,
            },
        ],
    },
]);

createRoot(document.getElementById("root")).render(
    <ConfigProvider
        theme={{
            token: {
                fontFamily: "shamel-2, sans-serif",
                colorPrimary: '#34a6ca',
                borderRadius: 0,
                // Alias Token
                colorBgContainer: '#ffffff',
            },
        }}
        direction="rtl">
        <RouterProvider router={router} />
    </ConfigProvider>
);
