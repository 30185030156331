import React, {useContext, useState} from 'react';
import {NavLink} from 'react-router-dom';
import "./navbar.css"
import {SidebarData} from "./SidebarData";
import logo from '../../assets/images/header_logo.png'
import {Button, message} from "antd";
import {LogoutOutlined} from "@ant-design/icons";
import {CookiesFunctions} from "../../common/functions";
import {LoginContext} from "../../App";
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
const Sidebar = ({children,isOpen}) => {
    const cookiesFun = new CookiesFunctions()
    const [,setLogin] = useContext(LoginContext)
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const logoutSession = async () => {
        setLoading(true)
        const body = {table:"sessions",data:{doc_id:cookiesFun.getSession(),running:"0"}}
        console.log(JSON.stringify(body))
        await axios.post(`${BASE_API_URL}update/`,body)
            .then(function (response) {
                setLoading(false)
                console.log(response.data)
                if (response.data){
                    logout()
                }else {
                    error()
                }
            });
    }
    const error = () => {
        messageApi.open({
            type: 'خطأ في الشبكة',
            content: 'خطأ في تسجيل الخروج، حاول مرة اخرى',
        });
    };
    const logout = () => {
        cookiesFun.setSession("")
        setLogin(false)
    }
    return (
        <div className="container-bar">
            {contextHolder}
            <div style={{width: isOpen ? "240px" : "50px"}} className="sidebar">
                <div className="top_section">
                    <img style={{display: isOpen ? "block" : "none"}} src={logo} alt="logo" className="logo" />
                </div>
                {
                    SidebarData.map((item, index)=> (
                        <NavLink to={item.path} key={index} className="link" >
                            <div className="icon">{item.icon}</div>
                            <div style={{display: isOpen ? "block" : "none"}} className="link_text">{item.label}</div>
                        </NavLink>
                       )
                    )
                }
                <Button loading={loading} onClick={logoutSession} style={{backgroundColor:"transparent",position:"absolute",bottom:"20px",right:"20px",
                    color:"red"}}
                        type="text" icon={<LogoutOutlined />}>تسجيل خروج</Button>
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;
