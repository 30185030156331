import React, {useEffect, useState} from 'react';
import TreeView from "../../components/tree_view";
import TableView from "../../components/table_view/table-view";
import {NodeModelContext} from "../../App";
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
import {Col, Divider, Row} from "antd";

const Services = () => {
    const [listData,setListData] = useState({tree:[],table:[]})
    const [context, setContext] = useState({key:"0",title:""});
    const [loading, setLoading] = useState({adding:false,fetch:false});
    const getData = async () => {
        setLoading({fetch:true,adding:false})
        await axios.post(BASE_API_URL,{table:"categories",tree:true})
            .then(function (response) {
                // console.log(response.data);
                setListData(response.data)
                setLoading({fetch:false,adding:false})
            })
            .catch(function (error) {
                setLoading({fetch:false,adding:false})
            });
    }
    useEffect(()=>{
        getData()
    },[])

    useEffect(()=>{
        getData()
    },[])

    const refresh = () => {
        getData()
    }
    return (
            <NodeModelContext.Provider value={[context, setContext]}>
                <Row>
                    <Col span={8}>
                        <TreeView treeData={listData.tree}/>
                    </Col>
                    <Divider type="vertical" />
                    <Col span={15}>
                        <TableView refresh={refresh} currentDataList={listData.table} loading={loading} setLoading={setLoading}/>
                    </Col>
                </Row>
            </NodeModelContext.Provider>
    );
};

export default Services;
