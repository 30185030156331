import React, { useEffect, useState} from 'react';
import { Table} from 'antd';
import axios from "axios";
import {BASE_API_URL} from "../../../const/consts";
import {EditableCell} from "../../../components/table_view/components/EditableCell";
import Model from "../../../components/model/model";

const ServiceOrder = ({order}) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const getData = async () => {
        setLoading(true)
        await axios.post(`${BASE_API_URL}orders/services_order/`,{table:"order_details",id:order.doc_id})
            .then(function (response) {
                setData(response.data)
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error)
                setLoading(false)
            });
    }
    useEffect(()=>{
        getData()
    },[order])
    const columns = [
        {
            title: 'الخدمة',
            dataIndex: 'service_id',
            required:true
        },
        {
            title: 'اختيار العميل',
            dataIndex: 'service_value',
            required:false
        }
    ];

    const [isModalOpen, setIsModalOpen] = useState({open:false,order:{}});
    const handleOk = () => {
        setIsModalOpen({open: false,order: {}});
    };
    const handleCancel = () => {
        setIsModalOpen({open: false,order: {}});
    };
    return (
        <div >
            <h2>جميع الخدمات</h2>
            <Table
                scroll={{
                    y: 350,
                }}
                loading={loading}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
            />
            <Model isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}/>
        </div>
    );
};
export default ServiceOrder;
