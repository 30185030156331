import React, {useContext, useEffect, useState} from 'react';
import {Button, Divider, Form, Table, Typography} from 'antd';
import {NodeModelContext} from "../../App";
import Link from "antd/lib/typography/Link";
import {EditableCell} from "./components/EditableCell";
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
import {CloseOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";

const TableView = ({currentDataList,loading,setLoading,refresh}) => {

    const columns = [
        {
            title: 'اسم الحقل',
            dataIndex: 'title',
            editable: true,
            required:true
        },
        {
            title: 'السعر ( اذا كان مطلوب )',
            dataIndex: 'price',
            width: '30%',
            editable: true,
            required:false
        },
        {
            title: 'اجراء',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                         <Link onClick={cancel}>
                            <CloseOutlined style={{ fontSize: '16px', color: '#414141' }}/>
                         </Link>
                    </span>
                ) : (
                    <div>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            <EditOutlined style={{ fontSize: '16px', color: '#6b815b' }}/>
                        </Typography.Link>
                        {/*<Divider type="vertical"/>*/}
                        {/*<Typography.Link disabled={editingKey !== ''} onClick={() => handelDelete(record.doc_id)}>*/}
                        {/*    <DeleteOutlined style={{ fontSize: '16px', color: '#ff4500' }}/>*/}
                        {/*</Typography.Link>*/}
                    </div>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'price' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                required: col.required,
                onKeyDown:onKeyDown
            }),
        };
    });
    const [context] = useContext(NodeModelContext);
    const [data, setData] = useState([]);

    useEffect(()=>{
        setData(currentDataList.filter(val=> val.parent_id === context.key))
    },[currentDataList,context])

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;

    const saveData = async (row,call) => {
        setLoading({fetch:false,adding:true})
        row.parent_id = context.key
        const method = adding?'insert':'update'
        await axios.post(`${BASE_API_URL}${method}/`,{table:"categories",data:row})
            .then(function (response) {
                call(response.data)
                console.log(response.data);
                setLoading({fetch:false,adding:false})
                refresh()
            })
            .catch(function (error) {
                console.log(error);
                setLoading({fetch:false,adding:false})
            });
    }

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                row.doc_id = item.doc_id
                console.log(row)
                console.log(item)
                await saveData(row,()=>{
                    setData(newData);
                    setEditingKey('');
                })
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const [adding,setAdding] = useState(false)
    const handleAdd = () => {
        if (editingKey){
            save(editingKey)
        }else {
            console.log("mmmm")
            setAdding(true)
            const docId = `${Date.now()}`
            const newData = {
                key: docId,
                title: ``,
                price: 0,
                doc_id: docId,
            };
            setData([newData,...data]);
            edit(newData)
        }
    };

    const edit = (record) => {
        form.setFieldsValue({
            title: '',
            price: '',
            ...record,
        });
        setEditingKey(record.key);
    };
    const handelDelete = async (docId) => {
        console.log(docId)
        await axios.post(`${BASE_API_URL}delete/`,{table:"categories",id:docId})
            .then(function (response) {
                console.log(response.data);
                if (response.data){
                    setData(data.filter((value)=> value.doc_id !== docId))
                }
                refresh()
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const cancel = () => {
        if (adding){
            console.log("editingKey")
            console.log(editingKey)
            setData(data.filter(item => item.key !== editingKey));
        }
        setEditingKey('');
        setAdding(false)
    };

    //shortcut
    const onKeyDown = (e) => {
        e = e || window.event;
        if(e.keyCode === 13) {
            if (editingKey){
                save(editingKey)
            }
        }
    }


    return (
        <Form form={form} component={false}>
            <h3>{context.title}</h3>
            <Button
                loading={loading.adding}
                onClick={handleAdd}
                type={"primary"}
                style={{
                    marginBottom: 16,
                    background:editingKey?"#0ba800":""
                }}
            >
                {editingKey?'حفظ البيانات':'اضافة جديد'}
            </Button>
            <Table
                scroll={{
                    y: 500,
                }}
                loading={loading.fetch}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
            />
        </Form>
    );
};
export default TableView;
