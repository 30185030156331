import { DownOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import React, {useContext} from "react";
import {NodeModelContext} from "../App";

const TreeView = ({treeData}) => {
    const [, setContext] = useContext(NodeModelContext);
    const onSelect = (selectedKeys, info) => {
        setContext({key:info.node.key,title:info.node.title})
    };
    return (
        <div className="max-column">
            <h3>جميع الخدمات</h3>
            <Tree
                showLine
                switcherIcon={<DownOutlined />}
                defaultExpandedKeys={['0-0-0']}
                onSelect={onSelect}
                treeData={treeData}
            />
        </div>
    );
};
export default TreeView;
