import React, {useState} from 'react';
import { NotificationOutlined} from '@ant-design/icons';
import {Badge, notification, Space} from 'antd';
import {onMessageListener} from "../messaging_init_in_sw";
import {openNotification} from "../App";
import {useNavigate} from "react-router-dom";
import url from "../assets/sound/new-order.mp3"
import useAudio from "./audio";
const NotificationBadge = () => {
    const [playing, toggle] = useAudio(url);

    const [show, setShow] = useState(0);
    const [fireNotification, setFireNotification] = useState({title: '', body: ''});
    const [api,contextHolder] = notification.useNotification();
    const navigate = useNavigate()

    onMessageListener().then(payload => {
        console.log("new order onMessageListener")
        openNotification(api,'topRight')
        setShow(show+1);
        toggle()
        setFireNotification({title: payload.notification.title, body: payload.notification.body})
        // console.log(payload);
    }).catch(err => console.log('failed: ', err));

    const goToOrders = () => {
        setShow(0)
        navigate("/orders/1")
    }

    return(
        <Space onClick={goToOrders} style={{marginRight: "10%"}} size="middle">
            {contextHolder}
            <Badge count={show}>
                <NotificationOutlined style={{fontSize: 20}}/>
            </Badge>
        </Space>
    )
}
export default NotificationBadge;