import React, {useContext, useState} from 'react';
import {Button, Form, Input, message, Spin} from 'antd';
import "./login.css"
import {LoadingOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import logo from "../../../assets/images/alamanlogo.jpg"
import axios from "axios";
import {BASE_API_URL} from "../../../const/consts";
import {CookiesFunctions} from "../../../common/functions";
import {LoginContext} from "../../../App";

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
const Login = ({loadingPage}) => {
    const [loading, setLoading] = useState(false);
    const cookiesFun = new CookiesFunctions()
    const [messageApi, contextHolder] = message.useMessage();
    const [,setLogin] = useContext(LoginContext)

    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    const error = (value) => {
        messageApi.open({
            type: 'error',
            content: value,
        });
    };
    const onFinish = async (values) => {
        setLoading(true)
        console.log('Success:', JSON.stringify(values));
        await axios.post(`${BASE_API_URL}auth/`,values)
            .then(function (response) {
                setLoading(false)
                if (response.data.session){
                    cookiesFun.setSession(response.data.session.sessionId)
                    setLogin(true)
                }else {
                    if (response.data.msg){
                        error(response.data.msg)
                    }
                }
            });
    };
    return <div className="login-container">
        {contextHolder}
        {loadingPage?
            <Spin indicator={antIcon} />:
            <Form
            name="normal_login"
            className="login-form"
            initialValues={{
                remember: true,
            }}
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
        >
            <div className="logo-container">
                <img className="logo" src={logo} alt="logo"/>
            </div>
            <h3 className="text-center">لوحة التحكم</h3>
            <Form.Item
                name="username"
                style={{
                    width: 250
                }}
                rules={[
                    {
                        required: true,
                        message: 'ادخل اسم المستخدم!',
                    },
                ]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="اسم المستخدم"/>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'ادخل كلمة المرور!',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="كلمة المرور"
                />
            </Form.Item>
            <Form.Item>
                <Button
                    loading={loading}
                    style={{
                        width: "100%"
                    }}
                    type="primary" htmlType="submit" className="login-form-button">
                    تسجيل دخول
                </Button>
            </Form.Item>
        </Form>}
    </div>
}
export default Login;
