import React, { useEffect, useState} from 'react';
import {Table, Tag} from 'antd';
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
import {EditableCell} from "../../components/table_view/components/EditableCell";

const Users = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const getData = async () => {
        setLoading(true)
        const body = {table:"users",role:"0"}
        console.log(JSON.stringify(body))
        await axios.post(BASE_API_URL,body)
            .then(function (response) {
                setData(response.data)
                setLoading(false)
            });
    }
    useEffect(()=>{
        getData()
    },[])
    const columns = [
        {
            title: 'اسم العميل',
            dataIndex: 'name',
        },
        {
            title: 'رقم الهاتف',
            dataIndex: 'phone',
        },
        {
            title: 'الايميل',
            dataIndex: 'email',
        },
        {
            title: 'كلمة المرور',
            dataIndex: 'password',
        },
        {
            title: 'الحالة',
            dataIndex: 'active',
            render: (_, record) => {
                return (
                    <div>
                        <Tag color={+record.active === 1?'green':'orange'}
                        >{+record.active === 1?'نشط':'معلق'}</Tag>
                    </div>
                )
            },
        }
    ];

    return (
        <div >
            <h2>جميع العملاء</h2>
            <Table
                scroll={{
                    y: 350,
                }}
                loading={loading}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
            />
        </div>
    );
};
export default Users;
