import { Layout } from 'antd';
import React from "react";
import {MenuOutlined} from "@ant-design/icons";
import NotificationBadge from "./badge";
const { Header, Content } = Layout;
const LayoutApp = ({children,toggle}) => {

    return (
        <Layout>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: '#ffffff',
                    }}
                >
                    <div style={{marginRight: "10px",fontSize: '25px',cursor:'pointer'}} >
                        <MenuOutlined onClick={toggle}/>
                        <NotificationBadge />
                    </div>
                </Header>
                <Content
                    style={{
                        margin: '16px 16px',
                        padding: 20,
                        minHeight: 280,
                        background: "white",
                        border: '1px solid #eaeaea',
                        borderRadius:'10px'
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};
export default LayoutApp;
