import React, {useEffect, useState} from 'react';
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
import "./home.css"
import {Skeleton} from "antd";
const Home = () => {
    const  body = {
        dashboard:[
            {table:"orders",field:"total",user:true,done:true},
            {table:"orders",field:"total",user:true,done:false},
            {table:"config"},
            {table:"users"}
        ]
    }
    const [loading, setLoading] = useState(false);
    const [tables, setTables] = useState({});
    const getData = async () => {
        setLoading(true)
        console.log(JSON.stringify(body))
        await axios.post(BASE_API_URL,body)
            .then(function (response) {
                setTables(response.data)
                setLoading(false)
            });
    }

    useEffect(()=> {
        getData()
    },[])

    return (
            loading?
                <div className="home">
                    <div className="cards">
                        <div className="card-home">
                            <div className="home-item-details">
                                <Skeleton.Input size={25} style={{borderRadius:'15px',marginTop:'10px'}}/>
                                <Skeleton.Input size={20} style={{borderRadius:'15px',marginTop:'10px'}}/>
                            </div>
                        </div>
                        <div className="card-home">
                            <div className="home-item-details">
                                <Skeleton.Input size={25} style={{borderRadius:'15px',marginTop:'10px'}}/>
                                <Skeleton.Input size={20} style={{borderRadius:'15px',marginTop:'10px'}}/>
                            </div>
                        </div>
                        <div className="card-home-dark">
                            <div className="home-item-details">
                                <Skeleton.Input size={25} style={{borderRadius:'15px',marginTop:'10px'}}/>
                                <Skeleton.Input size={20} style={{borderRadius:'15px',marginTop:'10px'}}/>
                            </div>
                        </div>
                        <div className="card-home">
                            <div className="home-item-details">
                                <Skeleton.Input size={25} style={{borderRadius:'15px',marginTop:'10px'}}/>
                                <Skeleton.Input size={20} style={{borderRadius:'15px',marginTop:'10px'}}/>
                            </div>
                        </div>
                        <div className="card-home">
                            <div className="home-item-details">
                                <Skeleton.Input size={25} style={{borderRadius:'15px',marginTop:'10px'}}/>
                                <Skeleton.Input size={20} style={{borderRadius:'15px',marginTop:'10px'}}/>
                            </div>
                        </div>
                    </div>
                </div>:
                <div>
                    <div className="home">
                        <div className="cards">
                            <div className="card-home">
                                <div className="home-item-details">
                                    <h1>{tables.orders?.count?tables.orders.count:""}</h1>
                                    <p>عدد الطلبات</p>
                                </div>
                            </div>
                            <div className="card-home">
                                <div className="home-item-details">
                                    <h1>{tables.orders?.total} ريال </h1>
                                    <p>الطلبات الغير مدفوعة</p>
                                    <p>العدد/ {tables.orders?.count?tables.orders.count:"0"}</p>
                                </div>
                            </div>
                            <div className="card-home-dark">
                                <div className="home-item-details">
                                    <h1>{tables.ordersDone?.total} ريال </h1>
                                    <p>اجمالي الطلبات المدفوعة</p>
                                    <p>{tables.ordersDone?.count?tables.ordersDone.count:"0"}العدد </p>
                                </div>
                            </div>
                            <div className="card-home">
                                <div className="home-item-details">
                                    <h1>{tables.config}</h1>
                                    <p>عدد زيارات الموقع</p>
                                </div>
                            </div>
                            <div className="card-home">
                                <div className="home-item-details">
                                    <h1>{tables.users}</h1>
                                    <p>عدد العملاء</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    );
};

export default Home;
