import React, {useEffect, useState} from 'react';
import "./invoice.css"
import logo from "../../assets/images/alamanlogo.jpg"
import axios from "axios";
import { useParams} from "react-router-dom";
import {BASE_API_URL} from "../../const/consts";
import {CookiesFunctions} from "../../common/functions";
const Invoice = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const {id} = useParams()
    const functions = new CookiesFunctions()
    useEffect(()=> {
        getData()
    },[])

    const getData = () => {
        setLoading(true)
        axios.post(`${BASE_API_URL}select/orders/`, {orderId: id}).then(value => {
            console.log(value.data)
            setData(value.data[0])
            setLoading(false)
        })
    }
    const printInvoice = () => {
        window.print();
    }
    const getSumItem = (price,value) => {
        let total = 0
        if (typeof value === "number"){
            total = value * price
        }
        return total
    }

    return (
        <div className= "invoice-wrapper" id = "print-area">
            {loading?<div className="text-center mt-5">تحميل الفاتورة ..</div>:
                <div className= "invoice">
                    <div className={+data.payment_status === 2?"pay-status green":"pay-status orange"}>
                        {+data.payment_status === 2?"مسددة":"مستحقة"}
                        <i style={{fontSize:"15px"}} className={+data.payment_status === 2?
                            "fa-solid fa-check pt-1":"fa-solid fa-circle-exclamation"}></i>
                    </div>
                    <div className= "invoice-container">
                        <div className= "invoice-head">
                            <div className= "invoice-head-top">
                                <div className= "invoice-head-top-left text-start">
                                    <img src={logo}  alt="logo"/>
                                </div>
                                <div className= "invoice-head-top-right text-end">
                                    <h3>فاتورة</h3>
                                </div>
                            </div>
                            <div className= "hr"></div>
                            <div className= "invoice-head-middle">
                                <div className= "invoice-head-middle-left text-start">
                                    <p><span className= "text-bold">التاريخ</span>: {data.created_at}</p>
                                </div>
                                <div className= "invoice-head-middle-right text-end">
                                    <p><span className= "text-bold">رقم الفاتورة:</span>{" "+data.doc_id}</p>
                                </div>
                            </div>
                            <div className= "invoice-head-middle">
                                <div className= "invoice-head-middle-left text-start">
                                    <p><span className= "text-bold">تاريخ الحجز</span>: {data.booked_date}</p>
                                </div>
                                <div className="invoice-head-middle-right text-end">
                                    <p><span className="text-bold">نوع الدفع:</span>
                                        {+data.payment_type === 2?"الكتروني" : "كاش"}
                                    </p>
                                </div>
                            </div>
                            <div className= "hr"></div>
                            <div className= "invoice-head-bottom">
                                <div className= "invoice-head-bottom-left">
                                    <ul>
                                        <li className= 'text-bold'>الي السيد/السادة:</li>
                                        <li>{data.user?.name}</li>
                                        <li>{functions.getCity(data.city)}</li>
                                        <li>{data.address}</li>
                                        {/*<li>United Kingdom</li>*/}
                                    </ul>
                                </div>
                                <div className= "invoice-head-bottom-right">
                                    <ul className= "text-end">
                                        <li className= 'text-bold'>تدفع الي:</li>
                                        <li>Alaman for cleaning</li>
                                        <li>Qatar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className= "overflow-view">
                            <div className= "invoice-body">
                                <table>
                                    <thead>
                                    <tr>
                                        <td className= "text-bold">الخدمة</td>
                                        <td className= "text-bold">البيان</td>
                                        <td className= "text-bold">السعر</td>
                                        <td className= "text-bold">المجموع</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.services.map((value,index) =>
                                        <tr key={index}>
                                            <td>{value.service_id}</td>
                                            <td>{value.service_value}</td>
                                            <td>{value.price}</td>
                                            <td>{getSumItem(value.price,value.service_value)}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                                <div className= "invoice-body-bottom">
                                    <div className= "invoice-body-info-item border-bottom">
                                        <div className= "info-item-td text-end text-bold">المجموع:</div>
                                        <div className= "info-item-td text-bold">{data.total} QAR</div>
                                    </div>
                                    <div className= "invoice-body-info-item border-bottom">
                                        <div className= "info-item-td text-end text-bold">تخفيض:</div>
                                        <div className= "info-item-td text-bold">{data.discount} QAR</div>
                                    </div>
                                    <div className= "invoice-body-info-item">
                                        <div className= "info-item-td text-end text-bold">الإجمالي:</div>
                                        <div className= "info-item-td text-bold f-20">{data.total - data.discount} QAR</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className= "invoice-foot text-center">
                            <div className= "invoice-btns">
                                <button type = "button" className= "invoice-btn" onClick={printInvoice}>
                                        <span>
                                            <i style={{fontSize:"16px",marginLeft:"10px"}} className="fa-solid fa-print"></i>
                                        </span>
                                    <span>طباعة</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Invoice;
