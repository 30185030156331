import React from "react";
import {
  FileDoneOutlined, PicRightOutlined,
  UsergroupAddOutlined,
  UserOutlined, UserSwitchOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";

export const SidebarData = [
  {
    key: '1',
    icon: <UserOutlined />,
    label: 'الرئيسية',
    path:"/"
  },
  {
    key: '2',
    icon: <PicRightOutlined />,
    label: 'ادارة الخدمات',
    path:"/services"
  },
  {
    key: '3',
    icon: <VideoCameraOutlined />,
    label: 'ادارة الطلبات',
    path:"/orders/1"
  },
  {
    key: '3',
    icon: <FileDoneOutlined />,
    label: 'الطلبات المكتملة',
    path:"/orders/2"
  },
  {
    key: '4',
    icon: <UsergroupAddOutlined />,
    label: 'العملاء',
    path:"/users"
  },
  {
    key: '5',
    icon: <UserSwitchOutlined />,
    label: 'العاملات',
    path:"/workers"
  },
];
