import React, {useEffect, useState} from 'react';
import {Badge, Divider, Table, Tag, Typography} from 'antd';
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
import {EditOutlined, EyeOutlined, MoreOutlined, SnippetsOutlined} from "@ant-design/icons";
import {EditableCell} from "../../components/table_view/components/EditableCell";
import Model from "../../components/model/model";
import {useNavigate, useParams} from "react-router-dom";
import DiscountModal from "./discount_modal";

const Orders = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate()
    const {newOrders} = useParams()
    const [discountModal,
        setDiscountModal] = useState({open:false,record: {},type:""});
    const getData = async () => {
        setLoading(true)
        let body = {
            query: "select * from orders where order_status != 3 && order_status != 5 ORDER BY id DESC",
            orders:"orders"
        }
        let url = `${BASE_API_URL}query/`
        if (newOrders === "2"){
            body = {
                table:"orders",
                where:{
                    field:"order_status",value:3,
                }
            }
            url = BASE_API_URL
        }
        await axios.post(url,body)
            .then(function (response) {
                setData(response.data)
                setLoading(false)
            });
    }
    useEffect(()=>{
        getData()
    },[newOrders])

    const viewInvoice = (id) => {
        navigate("/invoice/"+id)
    }
    const columns = [
        {
            title: 'رقم الطلب',
            dataIndex: 'doc_id',
            required:true
        },
        {
            title: 'اسم العميل',
            dataIndex: 'username',
            required:false
        },
        {
            title: 'رقم الهاتف',
            dataIndex: 'phone',
            required:false
        },
        {
            title: 'الاجمالي',
            dataIndex: 'total',
            required:false
        },
        {
            title: 'التخفيض',
            dataIndex: 'discount',
            required:false,
            render: (_, record) => {
                return (
                    <div onClick={()=> {
                        if (+record.order_status !== 3)
                            setDiscountModal({open: true, record: record, type: "discount"})
                      }
                    }
                         style={{
                             cursor:+record.order_status !== 3?"pointer":"",
                    }}
                    >
                        {record.discount}
                        {+record.order_status !== 3?<>
                            <Divider type="vertical"/>
                            <EditOutlined style={{ fontSize: '16px', color: '#6b815b' }}/>
                          </>:null
                        }
                    </div>
                )
            },
        },
        {
            title: 'حالة الدفع',
            dataIndex: 'payment_type',
            editable: true,
            required:false,
            render: (_, record) => {
                return (
                    <div>
                        <Tag color={+record.payment_status === 2?'green':'orange'}
                        >{+record.payment_status === 2?'تم':'لم يتم'}</Tag>
                    </div>
                )
            },
        },
        {
            title: 'حالة الطلب',
            dataIndex: 'order_status',
            editable: true,
            required:false,
            render: (_, record) => {
                return (
                    <div>
                        <Badge
                            onClick={()=> {
                                if (+record.order_status !== 3)
                                setDiscountModal({open: true,record: record, type: "status"})
                            }
                        }
                            count={+record.order_status === 3? "تم":
                                +record.order_status === 2?"في المعالجة":
                                    +record.order_status === 5?
                                    "ملغي":"جديد"}
                            style={{
                                cursor:+record.order_status !== 3 && +record.order_status !== 5?"pointer":"",
                                width:90,
                                paddingTop:3,
                                paddingBottom:5,
                                backgroundColor: +record.order_status === 3? "#42cc00":
                                    +record.order_status === 2?"#e1b157":
                                        +record.order_status === 5?
                                            "red":"#adadad",
                            }}
                           />
                    </div>
                )
            },
        },
        {
            title: 'اجراء',
            dataIndex: 'operation',
            render: (_, record) => {
                return <div>
                    <Typography.Link style={{ fontSize: '16px', color: '#ff8200' }} onClick={() => showModal(record)}>
                        <MoreOutlined />
                        المزيد
                    </Typography.Link>
                    <Divider type="vertical"/>
                    <Typography.Link onClick={() => showModal(record,true)}>
                        <EyeOutlined />
                    </Typography.Link>
                    <Divider type="vertical"/>
                    <Typography.Link onClick={()=> viewInvoice(record.doc_id)}>
                        <SnippetsOutlined />
                    </Typography.Link>
                  </div>
            },
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState({open:false,order:{},service:false});
    const showModal = (record,service) => {
        setIsModalOpen({open: true,order: record,service: service});
    };
    const handleOk = () => {
        setIsModalOpen({...isModalOpen,open: false});
    };
    const handleCancel = () => {
        setIsModalOpen({...isModalOpen,open: false});
    };

    return (
        <div>
            <h2>جميع الطلبات</h2>
            <Table
                scroll={{
                    y: 350,
                }}
                loading={loading}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={columns}
                rowClassName="editable-row"
            />
            <Model
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                call={getData}
            />
            <DiscountModal
                discountModal={discountModal}
                setDiscountModal={setDiscountModal}
                call={getData}
            />
        </div>
    );
};
export default Orders;
