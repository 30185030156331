import React, {useState} from 'react';
import {Button, Form, Input, Modal} from 'antd';
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
const DiscountModal = ({discountModal,setDiscountModal,call}) => {

    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("");

    const handleOk = () => {
        setDiscountModal({...discountModal,open: false});
    };
    const handleCancel = () => {
        setDiscountModal({...discountModal,open: false});
    };

    const onFinish = async () => {
        setLoading(true)
        let row = {doc_id:discountModal.record.doc_id,discount:value}
        if (discountModal.type === "status"){
            let status = +discountModal.record.order_status === 1?2:3;
              row = {doc_id:discountModal.record.doc_id,order_status:status}
        }
        const body = {table:"orders",data:row}
        console.log(JSON.stringify(body))
        await axios.post(`${BASE_API_URL}update/`,body)
            .then(function (response) {
                setLoading(false)
                handleCancel()
                call()
            });
    };

    const onChange = (e) => {
        setValue(e.target.value)
    };
    return (
        <>
            <Modal
                open={discountModal.open}
                title={discountModal.type === "status"?"تغيير حالة الطلب":"اجراء تخفيض"}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Form.Item>
                        <Button onClick={onFinish} key="submit" htmlType="submit" type="primary" loading={loading}>
                            {discountModal.type === "status"?"تغيير":"حفظ البيانات"}
                        </Button>
                    </Form.Item>
                ]}
            >
                <p> طلب رقم
                <p style={{fontSize:17,fontWeight:"bold"}}>{discountModal.record.doc_id}</p>
                </p>
                {discountModal.type === "status"?
                    <div> تغيير حالة الطلب الى {+discountModal.record.order_status === 1?
                        "في المعالجة":"تم التنفيذ"}</div>:
                    <Input
                    onChange={onChange}
                    value={value}
                    type="text"
                    placeholder="اكتب مبلغ التخفيض"
                    size="large"
                     />}
            </Modal>
        </>
    );
};
export default DiscountModal;