import React, { useEffect, useState} from 'react';
import {Button, Divider, Form, Switch, Table, Tag, Typography} from 'antd';
import Link from "antd/lib/typography/Link";
import axios from "axios";
import {BASE_API_URL} from "../../const/consts";
import {CloseOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {EditableCell} from "../../components/table_view/components/EditableCell";

const Workers = () => {
    const [loading, setLoading] = useState({adding:false,fetch:false});
    const [data, setData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState({id:"",loading:false});
    const getData = async () => {
        setLoading({fetch:true,adding:false})
        const body = {table:"users",role:"2"}
        // console.log(JSON.stringify(body))
        await axios.post(BASE_API_URL,body)
            .then(function (response) {
                setData(response.data)
                setLoading({fetch:false,adding:false})
            });
    }
    useEffect(()=>{
        getData()
    },[])

    const onChangeStatus = async (val,record) => {
        setLoadingStatus({id: record.uid,loading: true})
        const row = {uid:record.uid,status:val?1:0}
        await axios.post(`${BASE_API_URL}update/`,{table:"users",data:row})
            .then(function (response) {
                console.log(response.data);
                setLoadingStatus({id: "",loading: false})
            });
    };
    const columns = [
        {
            title: 'الاسم',
            dataIndex: 'name',
            editable: true,
            required:true
        },
        {
            title: 'الحالة',
            dataIndex: 'status',
            required:true,
            render: (_, record) => {
                return (
                    <div>
                        <Switch
                            checkedChildren="مشغول"
                            unCheckedChildren="متاح"
                            loading={loadingStatus.loading && loadingStatus.id === record.uid} defaultChecked={+record.status === 1} onChange={(val)=>
                            onChangeStatus(val,record)} />
                        {/*<Tag color={+record.active === 1?'green':'orange'}*/}
                        {/*>{+record.status === 0?'متاح':'مشغول'}</Tag>*/}
                    </div>
                )
            },
        },
        {
            title: 'الوضع',
            dataIndex: 'active',
            required:true,
            render: (_, record) => {
                return (
                    <div>
                        <Tag color={+record.active === 1?'green':'orange'}
                        >{+record.active === 1?'مفعل':'معلق'}</Tag>
                    </div>
                )
            },
        },
        {
            title: 'اجراء',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                         <Link onClick={cancel}>
                            <CloseOutlined style={{ fontSize: '16px', color: '#414141' }}/>
                         </Link>
                    </span>
                ) : (
                    <div>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            <EditOutlined style={{ fontSize: '16px', color: '#6b815b' }}/>
                        </Typography.Link>
                        <Divider type="vertical"/>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => handelDelete(record.uid)}>
                            <DeleteOutlined style={{ fontSize: '16px', color: '#ff4500' }}/>
                        </Typography.Link>
                    </div>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'price' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                required: col.required,
                onKeyDown:onKeyDown
            }),
        };
    });

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;

    const saveData = async (row,call) => {
        setLoading({fetch:false,adding:true})
        const method = adding?'insert':'update'
        if (adding){
            row.role = "2"
        }
        const body = {table:"users",data:row}
        console.log(JSON.stringify(body))
        await axios.post(`${BASE_API_URL}${method}/`,body)
            .then(function (response) {
                call(response.data)
                // console.log(response.data);
                setLoading({fetch:false,adding:false})
            })
            .catch(function (error) {
                console.log(error);
                setLoading({fetch:false,adding:false})
            });
    }

    const save = async (key) => {
        // console.log("ggggg")
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                row.uid = item.uid
                // console.log(row)
                // console.log(item)
                await saveData(row,()=>{
                    setData(newData);
                    setEditingKey('');
                })
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const [adding,setAdding] = useState(false)
    const handleAdd = () => {
        if (editingKey){
            save(editingKey)
        }else {
            // console.log("mmmm")
            setAdding(true)
            const docId = `${Date.now()}`
            const newData = {
                key: docId,
                name: '',
                status: 0,
                active: 1,
                uid: docId,
            };
            setData([newData,...data]);
            edit(newData)
        }
    };

    const edit = (record) => {
        form.setFieldsValue({
            name: '',
            status: '0',
            active: '1',
            ...record,
        });
        setEditingKey(record.key);
    };
    const handelDelete = async (docId) => {
        // console.log(docId)
        await axios.post(`${BASE_API_URL}delete/`,{table:"users",id:docId})
            .then(function (response) {
                // console.log(response.data);
                getData()
            })
            .catch(function (error) {
                // console.log(error);
            });
    };

    const cancel = () => {
        if (adding){
            console.log("editingKey")
            console.log(editingKey)
            setData(data.filter(item => item.key !== editingKey));
        }
        setEditingKey('');
        setAdding(false)
    };

    //shortcut
    const onKeyDown = (e) => {
        e = e || window.event;
        if(e.keyCode === 13) {
            if (editingKey){
                save(editingKey)
            }
        }
    }

    return (
        <Form form={form} component={false}>
            <h2>جميع العاملات</h2>
            <Button
                loading={loading.adding}
                onClick={handleAdd}
                type={"primary"}
                style={{
                    marginBottom: 16,
                    background:editingKey?"#0ba800":""
                }}
            >
                {editingKey?'حفظ البيانات':'اضافة جديد'}
            </Button>
            <Table
                scroll={{
                    y: 350,
                }}
                loading={loading.fetch}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
            />
        </Form>
    );
};
export default Workers;
